import { useTranslate } from '/machinery/I18n'
import { ButtonTertiary } from '/features/buildingBlocks/Button'
import { HeadingSm } from '/features/buildingBlocks/Heading'

import styles from './NoResultsCard.css'

export function NoResultsCard({ onResetClick, title, dataX }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.component} data-style-context="light">
      <HeadingSm h={4} layoutClassName={styles.noResultTitle} {...{ title }} />
      <ButtonTertiary label={__`reset-filters-label`} onClick={onResetClick} {...{ dataX }} />
    </div>
  )
}
